import { Outlet, Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <Outlet />
      <footer>
        &copy; 2023-2025 <a href="mailto:hello@tuze.ai">hello@tuze.ai</a>
        &nbsp;&middot;&nbsp;
        <Link to="https://sports.tuze.ai/privacy" target={"_hs"}>
          Privacy Policy
        </Link>
        &nbsp;&middot;&nbsp;
        <Link to="https://sports.tuze.ai/terms" target={"_hs"}>
          Terms of Service
        </Link>
        <p>
          * Past performance is no guarantee of future success. Beware small
          sample sizes. Leave the campsite better than you found it.
        </p>
      </footer>
    </>
  );
};

export default Footer;
